import React from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import { useSelector} from 'react-redux';

const PrivateRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  
   return ( isAuthenticated? <Outlet/> : <Navigate to="/" state={{ from : location?.pathname?.from ?? "/" }} replace /> )
}

export default PrivateRoutes