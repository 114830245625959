import React, {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from 'moment';
import Datetime from "react-datetime";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";

import "react-datetime/css/react-datetime.css";

const AccidentsReportManager = () => {

	const defaultForm = {

		//data check
		doneFormattingForServer: false,

		//section 1
		mostAccurateReflectsAccidentOrIncident: "", //Staff - Student - All - None
		whatTypeOfStudentGroupAreYouReportingFor:"",
		whatIsTheNameofYourSocietyCluborGroup:"",
		whatIsYourSUDepartment:"",
		whatAreYouReportingFor: "", //accident damage theft near_miss

		//section 2
		injuredPersonsFullName:"",
		injuredPersonsAddress:"",
		injuredPersonsPostcode:"",
		injuredPersonsAge:"",
		injuredPersonsInjuryType:"",
		injuredPersonsInjuryLocation:"",
		injuredPersonsFirstAid:"",
		injuredPersonsPermission:"",

		//section 3
		didTheEventHappenOnAUniversityCampus:"",
		whereDidTheEventHappen:"",
		whenDidTheAccidentHappen:"",
		pleaseDescribeHowTheEventHappened:"",
		//--Accident Injury Only
		afterTheInjuryDidAnyOfTheFollowingINJURY:[],
		whatCausedTheAccident:"",
		doAnyFirstAidKitsNeedToBeRefilled:"",
		//--Accident Damage Only
		whatHasBeenDamagedOrBroken:[],
		//Accident Damage & Incident Only
		afterTheEventDidAnyOfTheFollowingOthers:[],

		//section 4
		didAnybodyElseWitnessTheEvent:"",
		witness1Name:"",
		witness1Contact:"",
		witness2Name:"",
		witness2Contact:"",
		additionalWitnesses:"",

		//section 5
		fillerFullName:"",
		fillerEmailAddress:"",
		fillerAdditionalContact:"",

	}

	const [showModal,setShowModal] = useState(false);
    const [allData, setAllData] = useState([]);
	const [singleReportData, setSingleReportData] = useState(defaultForm);

	//filtering variables
    const [filterOn, setFilterOn] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [startEventDate, setStartEventDate] = useState("");
    const [endEventDate, setEndEventDate] = useState("");
	const [startSubmittedDate, setStartSubmittedDate] = useState("");
    const [endSubmittedDate, setEndSubmittedDate] = useState("");
    const [paginationData, setPaginationData] = useState({
		//java
		first:true,
		last:true,
		empty:false,
		size: 30,
		number:0,
		numberOfElements:0,
		totalElements:0,
		totalPages: 0,

		//mine
		loaded:false,
	});
	const {httpRequest}  = useAxios();

	const handleMarkReadUnread = async (id,action) => {
		httpRequest('business/activate/deactivate','POST',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.businessID === id ? {...data,businessActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	/*const handleDelete = async () => {

		httpRequest('business/delete','post',{id: deleteBusinessID}).then((data) =>{
			if(data){
				const newAllData = allData.filter((data) => data.businessID !== deleteBusinessID);
				setAllData(newAllData);
				dispatchNotification('success',"Business deleted successfully");
			}
		});
	}*/

	const getPaginationBody = () =>{
	    
		let count=0;
		let body = [];
	
		for(let i = paginationData.number ; i < paginationData.totalPages -1; i++){
			if(count === 5) break;//show only 5 links at anytime
			body.push(i);
			count++;
		}
		return body;	
	}

	const loadData = async (page = paginationData.number,size = paginationData.size, filterName = "", filterValue = "", type="filter") => {
		//console.log({page,size});
		let dest = "";
		switch(type){
			case "excel":
				dest="all/excel";
				httpRequest(`accident_reports/${dest}?pageNumber=${page}&pageSize=${size}&filterName=${filterName}&filterValue=${filterValue}`,'get',{},{'content-type': 'application/json'},"blob").then((data) =>{
					const href = URL.createObjectURL(data);

					// create "a" HTML element with href to file & click
					const link = document.createElement('a');
					link.href = href;
					link.setAttribute('download', "uonsu-accident-report.xls"); //or any other extension
					document.body.appendChild(link);
					link.click();

					// clean up "a" element & remove ObjectURL
					document.body.removeChild(link);
					URL.revokeObjectURL(href);
				});
				break;
			case "pdf":
				dest="all/pdf";
				httpRequest(`accident_reports/${dest}?pageNumber=${page}&pageSize=${size}&filterName=${filterName}&filterValue=${filterValue}`,'get',{},{'content-type': 'application/json'},"blob").then((data) =>{
					const href = URL.createObjectURL(data);

					// create "a" HTML element with href to file & click
					const link = document.createElement('a');
					link.href = href;
					link.setAttribute('download', "uonsu-accident-report.pdf"); //or any other extension
					document.body.appendChild(link);
					link.click();

					// clean up "a" element & remove ObjectURL
					document.body.removeChild(link);
					URL.revokeObjectURL(href);
				});
				break;
			default:
				dest="all"
				httpRequest(`accident_reports/${dest}?pageNumber=${page}&pageSize=${size}&filterName=${filterName}&filterValue=${filterValue}`,'GET').then((data) =>{
					if (data?.data?.reports) {
						setAllData(data.data.reports.content);
						let paginationData = {...data.data.reports, loaded: true};
						delete paginationData.content;
						delete paginationData.sort;
						delete paginationData.pageable;
						setPaginationData(paginationData);
					}
				});
		}
	}

	const typeBadgeSelector = (type) =>{
		switch (type){
			case "ACCIDENT - INJURY":
				return "danger";
			case "ACCIDENT - DAMAGE":
				return "secondary";
			case "INCIDENT":
				return "dark";
			case "NEAR-MISS":
				return "warning";
			default:
				return "info";
		}
	}

   const viewReport = (id) =>{
		//load and show
	   httpRequest(`accident_reports/report/${id}`,'GET',{}).then((data) =>{
		   if(data?.data?.report){
			    setSingleReportData(data.data.report);
		   }
	   });

	   setShowModal(true);
   }

	const downloadExcel = (id) =>{
		//load and show
		httpRequest(`accident_reports/report/excel/${id}`,'GET',{},{'content-type': 'application/json'},"blob").then((data) =>{
			const href = URL.createObjectURL(data);

			// create "a" HTML element with href to file & click
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', "uonsu-accident-report.xls"); //or any other extension
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		});
	}

	const downloadPdf = (id) =>{
		//load and show
		httpRequest(`accident_reports/report/pdf/${id}`,'GET',{},{'content-type': 'application/json'},"blob").then((data) =>{
			const href = URL.createObjectURL(data);

			// create "a" HTML element with href to file & click
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', "uonsu-accident-report.pdf"); //or any other extension
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		});
	}

   const paginationShowingDetails = () =>{
		if(paginationData.totalElements < paginationData.size)
			return `1 - ${paginationData.totalElements}`;
		else{
			if(paginationData.last)
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${paginationData.totalElements}`;
			else
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${(paginationData.number+1) * paginationData.size}`;
		}
   }

	useEffect(()=>{
		loadData();	
	},[]);

	return <>
			<div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_MANAGE_SU_DEPARTMENTS">
							<Link to="su_group/manage">
								<button type="button" className="btn btn-icon btn-danger">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">&nbsp;MANAGE SU DEPARTMENT LIST</span>
								</button>
							</Link>
						</AuthorizedAreas>

						<div className="card mb-4">
							
							<div className="row m-2">
								<div className="form-check form-switch">
									<input value={filterOn}
									checked={filterOn} onChange={()=>{
										setFilterOn(!filterOn);
										setFilterValue("");
										setStartEventDate("");
										setEndEventDate("");
										setStartSubmittedDate("");
										setEndSubmittedDate("");
									}
									}
									id="search" className="form-check-input" name="filter" type="checkbox" />
									<label htmlFor="search" className="form-check-label">Filter Accident Reports</label>
								</div>


								{filterOn ?
								<>
								<div className="input-group input-group-static">
										<label htmlFor="filterBy">Filter by:</label>
										<select
										className="form-control"
										name="filterBy"
										id="filterBy"
										autoComplete="off"
										defaultValue={""}
										value={filterName}
										onChange={e=>{
											setFilterName(e.target.value);
											setFilterValue("");
										}}
										>
											<option value=""> --Select Filter Type--</option>
											<option value="natureOfAccident"> Nature of Accident</option>
											<option value="typeOfAccident">Type of Accident</option>
											<option value="fillerName">Name of Filler</option>
											<option value="fillerEmail">Email of Filler</option>
											<option value="readStatus">Read Status</option>
											<option value="eventDate">Date of Event</option>
											<option value="submittedDate">Date of Submission</option>
										</select>
                      			</div>

								{filterName === "natureOfAccident"?
									<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="natureOfAccident" style={{ fontWeight:"bold", fontSize:"14px" }}>Select Nature of Accident</label>
											<select
												className="form-control"
												name="natureOfAccident"
												id="natureOfAccident"
												autoComplete="off"
												value={filterValue}
												onChange={e=>{
													setFilterValue(e.target.value);
												}}
											>
												<option value="">--Select Status--</option>
												<option value="STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)">STUDENT</option>
												<option value="STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work">STAFF</option>
												<option value="None of the above">NONE</option>
											</select>
										</div>
									</div>:null}

								{filterName === "typeOfAccident"?
									<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="typeOfAccident" style={{ fontWeight:"bold", fontSize:"14px" }}>Type of Accident</label>
											<select
												className="form-control"
												name="typeOfAccident"
												id="typeOfAccident"
												autoComplete="off"
												value={filterValue}
												onChange={e=>{
													setFilterValue(e.target.value);
												}}
											>
												<option value="">--Select Status--</option>
												<option value="ACCIDENT - Injury">ACCIDENT - Injury</option>
												<option value="ACCIDENT - Damage">ACCIDENT - Damage</option>
												<option value="INCIDENT">INCIDENT</option>
												<option value="NEAR-MISS">NEAR-MISS</option>
											</select>
										</div>
									</div>:null}

								{filterName === "fillerName" ?
								<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="fillerName" style={{ fontWeight:"bold", fontSize:"14px" }}>Search by Name</label>
											<input
												autoComplete="off"
												type="text"
												name="fillerName"
												id="fillerName"
												className="form-control"
												placeholder="Search by Name"
												value={filterValue}
												onChange={e=>{
												setFilterValue(e.target.value);
												}}				
											/>
										</div>
								</div>:null}

								{filterName === "fillerEmail" ?
									<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="fillerEmail" style={{ fontWeight:"bold", fontSize:"14px" }}>Search by Email</label>
											<input
												autoComplete="off"
												type="text"
												name="fillerEmail"
												id="fillerEmail"
												className="form-control"
												placeholder="Search by Email"
												value={filterValue}
												onChange={e=>{
													setFilterValue(e.target.value);
												}}
											/>
										</div>
									</div>:null}

								{filterName === "readStatus"?
									<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="typeOfAccident" style={{ fontWeight:"bold", fontSize:"14px" }}>Report Read Status</label>
											<select
												className="form-control"
												name="typeOfAccident"
												id="typeOfAccident"
												autoComplete="off"
												value={filterValue}
												onChange={e=>{
													setFilterValue(e.target.value);
												}}
											>
												<option value="">--Select Status--</option>
												<option value="1">Read</option>
												<option value="2">Unread</option>
											</select>
										</div>
									</div>:null}

								{filterName === "eventDate" ?
								<div className="row p-3">      
										<h6 className="font-weight-bold text-primary">Event Start Date</h6>
										<div className="col-12">
											<div className="row">

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="">Start Date Range</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
															name: "eventDay",
															value: startEventDate,
															}}
															onChange={(e) => {
															const newDay = moment(new Date(e._d)).format(
																"YYYY-MM-DD"
															);
															setFilterValue(newDay);
															setStartEventDate(newDay);
															setEndEventDate("");
															}}
															/* isValidDate={(current) =>
																current.isAfter(moment().subtract(1, "day"))
															} */
														/>
													</div>
												</div>

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="businessSubcategory">End Event Date</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
															name: "eventDay",
															value: endEventDate,
															}}
															onChange={(e) => {
															const newDay2 = moment(new Date(e._d)).format(
																"YYYY-MM-DD"
															);
															setFilterValue(startEventDate+"/"+newDay2);
															setEndEventDate(newDay2);
															}}
															isValidDate={(current) =>
																current.isAfter(moment(startEventDate))
															}
														/>
													</div>
												</div>

											</div>
										</div>
                                </div> 
							    :null}

								{filterName === "submittedDate" ?
									<div className="row p-3">
										<h6 className="font-weight-bold text-primary">Submitted Start Date</h6>
										<div className="col-12">
											<div className="row">

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="">Start Date Range</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
																name: "eventDay",
																value: startSubmittedDate,
															}}
															onChange={(e) => {
																const newDay = moment(new Date(e._d)).format(
																	"YYYY-MM-DD"
																);
																setFilterValue(newDay);
																setStartSubmittedDate(newDay);
																setEndSubmittedDate("");
															}}
															/* isValidDate={(current) =>
																current.isAfter(moment().subtract(1, "day"))
															} */
														/>
													</div>
												</div>

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="businessSubcategory">Submitted End Date</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
																name: "eventDay",
																value: endSubmittedDate,
															}}
															onChange={(e) => {
																const newDay2 = moment(new Date(e._d)).format(
																	"YYYY-MM-DD"
																);
																setFilterValue(startSubmittedDate+"/"+newDay2);
																setEndSubmittedDate(newDay2);
															}}
															isValidDate={(current) =>
																current.isAfter(moment(startSubmittedDate))
															}
														/>
													</div>
												</div>

											</div>
										</div>
									</div>
									:null}

								{filterName !== "" && filterValue !==""	?
									<>

										<button onClick={()=>loadData(0,paginationData.size,filterName,filterValue)} type="button" className="m-2 btn btn-icon btn-light">
											<span className="btn-inner--text"><span className="btn-inner--icon"> <i className="material-icons">search</i></span> FILTER</span>
										</button>

										<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_XLSX">
											<button onClick={()=>loadData(0,paginationData.size,filterName,filterValue,"excel")} type="button" className="m-2 btn btn-icon btn-light">
												<span className="btn-inner--text"><i className="fa-regular fa-file-excel"></i> DOWNLOAD EXCEL</span>
											</button>
										</AuthorizedAreas>

										<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_PDF">
											<button onClick={()=>loadData(0,paginationData.size,filterName,filterValue,"pdf")} type="button" className="m-2 btn btn-icon btn-light">
												<span className="btn-inner--text"> <i
													className="fa-regular fa-file-pdf"></i> DOWNLOAD PDF</span>
											</button>
										</AuthorizedAreas>
									</>:null}

								</>
								:null
								}
							</div>


							{paginationData.loaded && paginationData.totalElements > 0 ? <div className="alert alert-light p-1 m-2" role="alert">Showing {paginationShowingDetails()} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.totalElements}</span> items</div>:null}

							<div className="card-header pb-0">
								<h6>Accidents Report Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_MENU_ACCIDENT_REPORT_MANAGE">
									<div className="table-responsive p-0">
										
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													{/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th> */}
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Filled By
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Date of Event
													</th>

													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Date Submitted
													</th>
													
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Type of Event
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Viewed?
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.reportId}`}>
															{/* <td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td> */}
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			Name : {s.fillerFullName }<br/>
																			Email: {s.fillerEmailAddress }<br/>
																			{
																				s.fillerAdditionalContact.trim() !== "" ?
																				<>
																				Additional: {s.fillerAdditionalContact}
																				</>:
																				null
																			}

																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			<span className="">{ moment(s.whenDidTheAccidentHappen).format('LLL') }</span>

																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">

																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{ moment(s.submittedOn).format('LLL') }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">

																	<div className="d-flex flex-column justify-content-center">
																		<span className={`badge bg-${typeBadgeSelector(s.whatAreYouReportingFor.toUpperCase())}`}>
																			{ s.whatAreYouReportingFor.toUpperCase() }
																		</span>
																	</div>
																</div>
															</td>

															<td>
																<p className="font-weight-normal mb-0">
																	<span className={`badge bg-${s.reportHasBeenRead === 2 ? "success" : "light"}`}>
																	{s.reportHasBeenRead === 2 ? "UNREAD" : "READ"}
																	</span>
																</p>
															</td>

															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>viewReport(s.reportId)}
																				>
																					VIEW REPORT
																				</Link>
																			</li>
																		</AuthorizedAreas>


																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_MARK_READ">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleMarkReadUnread(s.reportId,s.reportHasBeenRead !== 2)}
																				>
																					MARK AS {s.reportHasBeenRead === 2 ? "READ" : "UNREAD"}
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_XLSX">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>downloadExcel(s.reportId)}
																				>
																					DOWNLOAD AS EXCEL
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_ACCIDENT_REPORT_DOWNLOAD_REPORTS_PDF">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`#`}
																					state={{id: s.reportId}}
																					onClick={()=>downloadPdf(s.reportId)}
																				>
																					DOWNLOAD AS PDF
																				</Link>
																			</li>
																		</AuthorizedAreas>


																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>


					{
					paginationData.totalElements && paginationData.totalPages > 1 ?
							<div className="m-3">
								<nav aria-label="...">
								<ul className="pagination">
									
									<li className={`page-item ${paginationData.number > 0  ? 'cursor-pointer': 'disabled'}`}>
									<span 
									className="page-link" 
									onClick={()=>loadData(paginationData.number - 1,paginationData.size, filterName, filterValue)}>Prev</span>
									</li>

									{paginationData.first!==true ?
									<li className={`page-item ${paginationData.first === true ? 'active': 'cursor-pointer'}`}>
										<span 
										className="page-link" 
										onClick={()=>{ if(paginationData.number === 0) return; loadData(0,paginationData.size, filterName, filterValue)}} >1</span>
									</li>:null}

									{
									getPaginationBody().map(el=> (
									     <li key={el} className={`page-item  ${el===paginationData.number ? 'active':'cursor-pointer'}`} aria-current="page">
				   						      <span 
											  className="page-link"
											  onClick={()=>{ if(paginationData.number === el) return; loadData(el,paginationData.size, filterName, filterValue)}}
											  >{el+1}</span>
										</li>))
									}


									<li className={`page-item ${paginationData.number === paginationData.totalPages-1  ? 'active': 'cursor-pointer'}`}>
										<span 
										className="page-link" 
										onClick={()=>{ if(paginationData.number === paginationData.totalPages-1) return; loadData(paginationData.totalPages-1,paginationData.size, filterName, filterValue)}}  >{paginationData.totalPages}</span>
									</li>

									<li className={`page-item ${paginationData.last !== true ? 'cursor-pointer': 'disabled'}`}>
										<span 
										className="page-link" 
										onClick={()=>loadData(paginationData.number+1,paginationData.size, filterName, filterValue)}>Next</span>
									</li>
								</ul>
								</nav>
							</div> 
						:
					null
					}

								</AuthorizedAreas>
							</div>
						</div>
					</div>


		   </div>

			{/*Modal*/}
			<Modal
				style={{ zIndex: "9999999999999999999999999999999999999999999999999999999" }}
				show={showModal}
				fullscreen={false}
				size="xl"
				scrollable={false}
				centered
				backdrop="static"
				keyboard={false}
				onHide={()=>setShowModal(false)}>
				<Modal.Header closeButton={false}>
					<Modal.Title>Report Details <i style={{ position:'absolute', right:'15px', top: '10px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowModal(false)} className="fa fa-circle-xmark"></i></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container">

						{/*Section 1*/}
						<div>
								<div className="mb-5">
									<label className="form-label"> <strong className="text-danger">*</strong> Which of the following statements <strong>MOST ACCURATELY</strong> reflects the accident/incident?</label>
									<input className="form-control" readOnly={true} value={singleReportData.mostAccurateReflectsAccidentOrIncident} type="text" />
								</div>

							{
								singleReportData.mostAccurateReflectsAccidentOrIncident === "STUDENT - The Accident/Incident occurred at a STUDENT - Led activity (e.g. Society, Club, SRS or Network activity)"?
									<>
										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What Type of Student Group are you reporting for?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatTypeOfStudentGroupAreYouReportingFor} type="text" />
										</div>

										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What is the Name of your Society, Club or Group?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatIsTheNameofYourSocietyCluborGroup} type="text" />
										</div>
									</>
									:
									null
							}

							{
								singleReportData.mostAccurateReflectsAccidentOrIncident === "None of the above" || singleReportData.mostAccurateReflectsAccidentOrIncident === "STAFF - The Accident Involved an SU Staff Member or happened to a member of the public at an SU Place of Work"?
									<>
										<div className="mb-5">
											<label className="form-label"> <strong className="text-danger">*</strong> What is your SU Department?</label>
											<input className="form-control" readOnly={true} value={singleReportData.whatIsYourSUDepartment} type="text" />
										</div>
									</>
									:
									null
							}

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> What are you Reporting?</label>
								<input className="form-control" readOnly={true} value={singleReportData.whatAreYouReportingFor} type="text" />
							</div>
						</div>

						{/*Section 2 ACCIDENT - INJURY*/}
						<div>

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Injury" ?
								<>
									<h4 style={{ color : "#171635"}}><i className="bi bi-patch-question-fill"></i> About the person who had the Accident</h4>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Full Name?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsFullName} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Address?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsAddress} type="text" />
									</div>

									{
										singleReportData.injuredPersonsPostcode.trim() !== "" ?
									<div className="mb-5">
										<label className="form-label"> Injured Person's Postcode?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsPostcode} type="text" />
									</div>
										:
										null
									}

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Injured Person's Age?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsAge} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What Injuries has the person suffered?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsInjuryType} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Where on the body are the injuries?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsInjuryLocation} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What First Aid Treatment was given on the scene?</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.injuredPersonsFirstAid} type="text" style={{height: "7rem"}}></textarea>
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Does the Injured Person give permission for personal and/or contact information to be passed on to the University?</label>
										<input className="form-control" readOnly={true} value={singleReportData.injuredPersonsPermission} type="text" />
									</div>


								</>
								:
								null
							}

						</div>

						{/*Section 3 About Event*/}
						<div>
							<h4 style={{ color : "#171635"}}><i className="bi bi-question-square-fill"></i> About the <strong>{singleReportData.whatAreYouReportingFor}</strong></h4>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Did the {singleReportData.whatAreYouReportingFor} happen on a University Campus (or University owned property)?</label>
								<input className="form-control" readOnly={true} value={singleReportData.didTheEventHappenOnAUniversityCampus} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> <strong>WHERE</strong> did the {singleReportData.whatAreYouReportingFor} Happen?</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.whereDidTheEventHappen} type="text" style={{height: "7rem"}}></textarea>
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> <strong>WHEN</strong> did the {singleReportData.whatAreYouReportingFor} Happen?</label>
								<input className="form-control" readOnly={true} value={moment(new Date(singleReportData.whenDidTheAccidentHappen)).format("YYYY-MM-DD")} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Please describe how the {singleReportData.whatAreYouReportingFor} happened</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.pleaseDescribeHowTheEventHappened} type="text" style={{height: "7rem"}}></textarea>
							</div>

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Injury" ?
								<>
									<div className="mb-5">
										<label className="form-label"><strong className="text-danger">*</strong> After the Injury Accident - Did any of the following occur?</label>
										{
											singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.trim() !== "" && singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.includes(";")?

												<>
												{
													singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY.split(";").map(answer=>
													  <div className="form-check mt-3">
														 <input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
														 <label className="form-check-label">{answer}</label>
													  </div>
													)
												})

												</>
												:
												<div className="form-check mt-3">
													<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY} />
													<label className="form-check-label">{singleReportData.afterTheInjuryDidAnyOfTheFollowingINJURY}</label>
												</div>
										}
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> What caused the accident?</label>
										<input className="form-control" readOnly={true} value={singleReportData.whatCausedTheAccident} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Do any first aid kits need to be refilled after treating this incident?</label>
										<input className="form-control" readOnly={true} value={singleReportData.doAnyFirstAidKitsNeedToBeRefilled} type="text" />
									</div>

								</>
								:
								null
							}

							{
								singleReportData.whatAreYouReportingFor === "ACCIDENT - Damage" ?
									<>
										<div className="mb-5">
											<label className="form-label"><strong className="text-danger">*</strong> What has been damaged or broken?</label>
											{
												singleReportData.whatHasBeenDamagedOrBroken.trim() !== "" && singleReportData.whatHasBeenDamagedOrBroken.includes(";")?

													<>
														{
															singleReportData.whatHasBeenDamagedOrBroken.split(";").map(answer=>
																<div className="form-check mt-3">
																	<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
																	<label className="form-check-label">{answer}</label>
																</div>
															)
														})

													</>
													:
													<div className="form-check mt-3">
														<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.whatHasBeenDamagedOrBroken} />
														<label className="form-check-label">{singleReportData.whatHasBeenDamagedOrBroken}</label>
													</div>
											}
										</div>
									</>
									:
									null
							}

							{singleReportData.whatAreYouReportingFor === "ACCIDENT - Damage" || singleReportData.whatAreYouReportingFor === "INCIDENT" ?
								<>
									<div className="mb-5">
										<label className="form-label"><strong className="text-danger">*</strong> What has been damaged or broken?</label>
										{
											singleReportData.afterTheEventDidAnyOfTheFollowingOthers.trim() !== "" && singleReportData.afterTheEventDidAnyOfTheFollowingOthers.includes(";")?

												<>
													{
														singleReportData.afterTheEventDidAnyOfTheFollowingOthers.split(";").map(answer=>
															<div key={answer} className="form-check mt-3">
																<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={answer} />
																<label className="form-check-label">{answer}</label>
															</div>
														)
													}

												</>
												:
												<div className="form-check mt-3">
													<input checked={true} readOnly={true} className="form-check-input" type="checkbox" value={singleReportData.afterTheEventDidAnyOfTheFollowingOthers} />
													<label className="form-check-label">{singleReportData.afterTheEventDidAnyOfTheFollowingOthers}</label>
												</div>
										}
									</div>

								</>
								:
								null
							}

						</div>

						{/*Section 4 Witnesses*/}
						<div>
							<h4 style={{ color : "#171635"}}><i className="bi bi-people-fill"></i> Witnesses</h4>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Did Anybody Else Witness the Accident/Incident or Near-miss?</label>
								<input className="form-control" readOnly={true} value={singleReportData.didAnybodyElseWitnessTheEvent} type="text" />
							</div>

							{
								singleReportData.didAnybodyElseWitnessTheEvent === "YES" ?
								<>
									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Witness #1 Name:</label>
										<input className="form-control" readOnly={true} value={singleReportData.witness1Name} type="text" />
									</div>

									<div className="mb-5">
										<label className="form-label"> <strong className="text-danger">*</strong> Witness #1 Contact Details:</label>
										<textarea className="form-control mt-3" readOnly={true} value={singleReportData.witness1Contact} type="text" style={{height: "7rem"}}></textarea>
									</div>

									{
										singleReportData.witness2Name.trim() !== "" ?
											<>
												<div className="mb-5">
													<label className="form-label"> Witness #2 Name:</label>
													<input className="form-control" readOnly={true} value={singleReportData.witness2Name} type="text" />
												</div>

												<div className="mb-5">
													<label className="form-label">  Witness #2 Contact Details:</label>
													<textarea className="form-control mt-3" readOnly={true} value={singleReportData.witness2Contact} type="text" style={{height: "7rem"}}></textarea>
												</div>
											</>
											:null
									}

									{
										singleReportData.additionalWitnesses.trim() !== "" ?
											<>
												<div className="mb-5">
													<label className="form-label"> Add details of any additional witnesses below : </label>
													<textarea className="form-control mt-3" readOnly={true} value={singleReportData.additionalWitnesses} type="text" style={{height: "7rem"}}></textarea>
												</div>
											</>
											:null
									}

								</>
								:
								null
							}

						</div>

						{/*Section 5 Filler Details*/}
						<>
							<div className="mb-5">
								<h4 style={{ color : "#171635"}}><i className="bi bi-person-vcard"></i> About the Person Completing this Form</h4>
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Your Full Name</label>
								<input className="form-control" readOnly={true} value={singleReportData.fillerFullName} type="text" />
							</div>

							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Your Email Name</label>
								<input className="form-control" readOnly={true} value={singleReportData.fillerEmailAddress} type="text" />
							</div>

							{singleReportData.fillerAdditionalContact.trim() !== "" ?
							<div className="mb-5">
								<label className="form-label"> <strong className="text-danger">*</strong> Any Additional Contact Details</label>
								<textarea className="form-control mt-3" readOnly={true} value={singleReportData.fillerAdditionalContact} type="text" style={{height: "7rem"}}></textarea>
							</div> : null }
						</>


					</div>
				</Modal.Body>
				<Modal.Footer>
					 <button className="btn btn-danger" onClick={()=>setShowModal(false)}>Close</button>
				</Modal.Footer>
			</Modal>

		</>

}

export default AccidentsReportManager