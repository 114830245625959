import React, {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from "moment/moment";

const UserManager = () => {
    const [allUser, setAllUser] = useState([]);
	const [filterOn, setFilterOn] = useState(false);
	const [filterName, setFilterName] = useState("");
	const [filterValue, setFilterValue] = useState("");
	const [paginationData, setPaginationData] = useState({
		//java
		first:true,
		last:true,
		empty:false,
		size: 30,
		number:0,
		numberOfElements:0,
		totalElements:0,
		totalPages: 0,

		//mine
		loaded:false,
	});
	const {httpRequest}  = useAxios();

	const getPaginationBody = () =>{

		let count=0;
		let body = [];

		for(let i = paginationData.number ; i < paginationData.totalPages -1; i++){
			if(count === 5) break;//show only 5 links at anytime
			body.push(i);
			count++;
		}
		return body;
	}

	const handleActivateDeactivate = async (userId,newStatus) => {
		httpRequest(`user/status/update/${newStatus}/${userId}`,'PATCH',{}).then((data) =>{
			if(data){
				const newAllStaff = allUser.map((staff) =>{
					return staff.userId === userId ? {...staff,status: newStatus} : staff;
				});
				setAllUser(newAllStaff);
			}
		});
	}

	const paginationShowingDetails = () =>{
		if(paginationData.totalElements < paginationData.size)
			return `1 - ${paginationData.totalElements}`;
		else{
			if(paginationData.last)
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${paginationData.totalElements}`;
			else
				return `${ 1 + ( (paginationData.number ) * paginationData.size)} - ${(paginationData.number+1) * paginationData.size}`;
		}
	}

	const loadData = async (page = paginationData.number,size = paginationData.size, filterName = "", filterValue = "") => {
		httpRequest(`user/all?pageNumber=${page}&pageSize=${size}&filterName=${filterName}&filterValue=${filterValue}`,'GET').then((data) =>{
			if (data?.data?.users) {
				setAllUser(data.data.users.content);
				let paginationData = {...data.data.users, loaded: true};
				delete paginationData.content;
				delete paginationData.sort;
				delete paginationData.pageable;
				setPaginationData(paginationData);
			}
		});
	}

	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_USER_CREATE">
							<Link to="new">
								<button type="button" className="btn btn-icon btn-info">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">CREATE USER</span>
								</button>
							</Link>
						</AuthorizedAreas>


						{paginationData.loaded && paginationData.totalElements > 0 ? <div className="alert alert-light p-1 m-2" role="alert">Showing {paginationShowingDetails()} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.totalElements}</span> items</div>:null}

						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>User Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_MENU_USER_MANAGE">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S/N</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Designation</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Role</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date Created</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allUser.length > 0 ? (
													allUser.map((s, index) => (
														<tr key={`${s.uuid}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">{s.firstname + " " + s.lastname}</h6>
																		<p>{s.email}</p>
																	</div>
																</div>
															</td>
															<td><p className="text-sm font-weight-normal mb-0">{s.designation}</p></td>
															<td><p className="text-sm font-weight-normal mb-0">{s.role}</p></td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.status === 1 ? "success" : "primary"}`}>
																	{s.status === 1 ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
															<td><p className="text-sm font-weight-normal mb-0">{moment(new Date(s.dateCreated)).format("YYYY-MM-DD")}</p></td>
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		{/*<AuthorizedAreas permission="CAN_USER_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`view`}
																					state={{ userId: s.userId }}
																				>
																					VIEW USER
																				</Link>
																			</li>
																		</AuthorizedAreas>*/}
																		<AuthorizedAreas permission="CAN_USER_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{ userId: s.userId }}
																				>
																					EDIT USER
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_USER_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.userId,s.status === 1 ? 2 : 1)}
																				>
																					{s.status === 1 ? "DE-ACTIVATE" : "ACTIVATE"} USER
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>

									{
										paginationData.totalElements && paginationData.totalPages > 1 ?
											<div className="m-3">
												<nav aria-label="...">
													<ul className="pagination">

														<li className={`page-item ${paginationData.number > 0  ? 'cursor-pointer': 'disabled'}`}>
									<span
										className="page-link"
										onClick={()=>loadData(paginationData.number - 1,paginationData.size, filterName, filterValue)}>Prev</span>
														</li>

														{paginationData.first!==true ?
															<li className={`page-item ${paginationData.first === true ? 'active': 'cursor-pointer'}`}>
										<span
											className="page-link"
											onClick={()=>{ if(paginationData.number === 0) return; loadData(0,paginationData.size, filterName, filterValue)}} >1</span>
															</li>:null}

														{
															getPaginationBody().map(el=> (
																<li key={el} className={`page-item  ${el===paginationData.number ? 'active':'cursor-pointer'}`} aria-current="page">
				   						      <span
												  className="page-link"
												  onClick={()=>{ if(paginationData.number === el) return; loadData(el,paginationData.size, filterName, filterValue)}}
											  >{el+1}</span>
																</li>))
														}


														<li className={`page-item ${paginationData.number === paginationData.totalPages-1  ? 'active': 'cursor-pointer'}`}>
										<span
											className="page-link"
											onClick={()=>{ if(paginationData.number === paginationData.totalPages-1) return; loadData(paginationData.totalPages-1,paginationData.size, filterName, filterValue)}}  >{paginationData.totalPages}</span>
														</li>

														<li className={`page-item ${paginationData.last !== true ? 'cursor-pointer': 'disabled'}`}>
										<span
											className="page-link"
											onClick={()=>loadData(paginationData.number+1,paginationData.size, filterName, filterValue)}>Next</span>
														</li>
													</ul>
												</nav>
											</div>
											:
											null
									}

								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default UserManager