import useGlobalNotification from "./useGlobalNotification";
import useRefreshToken from "./useRefreshToken";
import axios from '../../api/axios';
import {CHANGE_LOADING_STATE} from "../../redux/slice/authSlice";
import { useDispatch, useSelector} from "react-redux";
import { useLocation} from "react-router-dom";

const useAxios = () => {
    const {accessToken} = useSelector((state)=>state.auth);
    const {dispatchNotification} = useGlobalNotification();
    const dispatch = useDispatch();
    const refresh = useRefreshToken();
    const location = useLocation();

    const httpRequest = async (url, method, values={}, additionalHeaders={'content-type': 'application/json'},responseType="json") =>{
        dispatch(CHANGE_LOADING_STATE(true));
        const options = { authorization: `Bearer ${accessToken}`,...additionalHeaders };
        try {
            const response = await axios({
                method,
                url,
                // `responseType` indicates the type of data that the server will respond with
                // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
                //   browser only: 'blob'
                //default is json
                responseType: responseType, // important
                headers: options,
                 data: values
            });
            return response.data;
        } catch (err) {
            //console.log("Sent from useAxios--->", err);
            if(err?.response?.status === 403){
                //FORBIDDEN
                //token is invalid
				//refresh token here
                //console.log("Attempting refreshing token");
                 const newAccessToken = await refresh();
                 if(newAccessToken){
                    try{
                        const response = await axios({
                            method,
                            url,
                            headers: { authorization: `Bearer ${newAccessToken}` },
                            data: {...values}
                        });
                        return response.data;
                    }catch(err){
                        dispatchNotification('An error','Error occurred');  
                    }
                    finally{
                         dispatch(CHANGE_LOADING_STATE(false));
                    }
             }else{
                //dispatchNotification('error','An error occurred, Try again')
             }
			}
            else if(err?.response?.status === 401){
                dispatchNotification('error',"Session Expired. Kindly re-login");
            }
            else if (!err?.response) {
                dispatchNotification('error','Timed out. Kindly refresh browser');
               // window.location.reload()
			}else if (err.response.data?.error) {
                dispatchNotification('error',err.response.data.error);
			}
            else if (err.response.data?.errors) {
                let message = "<p>You have the following errors:</p>";
                message  += err.response.data.errors.join("<br/>");
                dispatchNotification('error',message);
            }
            else{
               // window.location.reload();
                dispatchNotification('error','There was a general error');
			}
        }finally{
            dispatch(CHANGE_LOADING_STATE(false));
        }
       
		
    }
    
    return {httpRequest}
}

export default useAxios