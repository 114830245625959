import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate } from "react-router-dom";
import { RoleCreateValidation } from "../../../utils/validations/RoleCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewRole = () => {

  const [roleName, setRoleName] = useState("");
  const [allocatedPermissions, setAllocatedPermissions] = useState([]);

  const [permissionCategories,setPermissionCategories] = useState([]);
  const [permissions,setPermissions] = useState([]);
  const [allAssignedClicked, setAllAssignedClicked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {dispatchNotification} = useGlobalNotification();
  const {httpRequest}  = useAxios();

  const getInitializationData = async () =>{
	await httpRequest('role/initialize','GET')
			.then((data) =>{
				if(data){
					setPermissions(data.data.permissions);
					setPermissionCategories(data.data.permissionsCategory);
				}
	}); 
  }

  useEffect(() => {
		getInitializationData();
  },[]);

      const amIChecked = (id) =>{ return allocatedPermissions.includes(id)}
      const handleCheckbox = (id) =>{
          if(allocatedPermissions.includes(id))
              setAllocatedPermissions(prev=>(prev.filter(e=>e!==id)));
          else
              setAllocatedPermissions(prev=>([...prev,id]));
      }
      const handleAssignAll = () =>{
         allAssignedClicked ? setAllocatedPermissions([]) : setAllocatedPermissions(permissions.map(p=>p.permissionId));
         setAllAssignedClicked(!allAssignedClicked);
      }

  const handleSubmit = async (e) => {
        e.preventDefault();
        //validation
        RoleCreateValidation.validate({roleName}, { abortEarly: false })
        .then(async (values)=>{
            await httpRequest('role/save','POST',{roleName,permissions:allocatedPermissions})
            .then((data) =>{
                if(data){
                    dispatchNotification('success',"Created successfully");
                   	//setForm(defaultForm);
                    setTimeout(()=>{navigate(-1);},1000);
                }
            });   
        })
        .catch(err=>{
            let message = "<p>You have the following errors:</p>";
            message  += err.errors.join("<br/>");
            dispatchNotification('error',message);
        });
    };
	
	
	return <AuthorizedAreas permission="CAN_ROLE_CREATE">

	<div className="card-body px-0 pt-0 pb-2">
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
				<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>Role Information</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="roleName">Role Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="roleName"
                          id="roleName"
                          className="form-control"
                          placeholder="Enter New Role Name"
                          value={roleName}
						  onChange={(e)=>{setRoleName(e.target.value)}}
                        />
                      </div>
                    </div>
                </div>

                  <h6 className="roleNamePermissionShow mt-3">Permissions <span onClick={()=>handleAssignAll()} className="badge badge-warning badge-sm cursor-pointer">{ allAssignedClicked ? "UnAssign" : "Assign"} All Permissions</span>
                    <span className="roleNamePermission text-primary" />
                  </h6>
                    {
                     permissionCategories.length > 0 ?   permissionCategories.map(
                         pc   =>
                           <div className="row mt-4">
                               <h6>{pc.permissionCategory}</h6>
                                            {
                                                permissions.length > 0 ?
                                                    permissions.map((p) => (

                                                            pc.permissionCategoryId === p.permissionCategoryId ?
                                                                <div key={p.permissionId} className="col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            value={p.permissionId}
                                                                            checked={amIChecked(p.permissionId)}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`idHere${p.permissionId}`}
                                                                            onChange={()=>handleCheckbox(p.permissionId)}
                                                                        />
                                                                        <label htmlFor={`idHere${p.permissionId}`} className="form-check-label">{p.permissionName}</label>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null

                                                )) :
                                                <h6>Role has no permissions</h6>
                                            }
                            </div>
                              )
                    :
                         null
                    }

                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
					<span className="btn-inner--icon"> <i className="material-icons">save</i></span>
					<span className="btn-inner--text">CREATE ROLE</span>
				</button>

                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default NewRole