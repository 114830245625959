import React,{useEffect} from 'react';
import { useSelector } from "react-redux";

const Dashboard = () => {
	const state = useSelector((state) => state.auth);
  return (
		<div className="container-fluid py-4">
			<h6>Welcome. Kindly use the navigation on the left to maximize your experience</h6>
			{/* <div className="row">
				<div className="col-sm-4">
					<div className="card">
						<div className="card-body p-3 position-relative">
							<div className="row">
								<div className="col-7 text-start">
									<p className="text-sm mb-1 text-capitalize font-weight-bold">
										Sales
									</p>
									<h5 className="font-weight-bolder mb-0">$230,220</h5>
									<span className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
										+55%{" "}
										<span className="font-weight-normal text-secondary">
											since last month
										</span>
									</span>
								</div>
								<div className="col-5">
									<div className="dropdown text-end">
										<a
											href="javascript:;"
											className="cursor-pointer text-secondary"
											id="dropdownUsers1"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<span className="text-xs text-secondary">
												6 May - 7 May
											</span>
										</a>
										<ul
											className="dropdown-menu dropdown-menu-end px-2 py-3"
											aria-labelledby="dropdownUsers1"
										>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 7 days
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last week
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 30 days
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-4 mt-sm-0 mt-4">
					<div className="card">
						<div className="card-body p-3 position-relative">
							<div className="row">
								<div className="col-7 text-start">
									<p className="text-sm mb-1 text-capitalize font-weight-bold">
										Customers
									</p>
									<h5 className="font-weight-bolder mb-0">3.200</h5>
									<span className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
										+12%{" "}
										<span className="font-weight-normal text-secondary">
											since last month
										</span>
									</span>
								</div>
								<div className="col-5">
									<div className="dropdown text-end">
										<a
											href="javascript:;"
											className="cursor-pointer text-secondary"
											id="dropdownUsers2"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<span className="text-xs text-secondary">
												6 May - 7 May
											</span>
										</a>
										<ul
											className="dropdown-menu dropdown-menu-end px-2 py-3"
											aria-labelledby="dropdownUsers2"
										>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 7 days
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last week
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 30 days
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-4 mt-sm-0 mt-4">
					<div className="card">
						<div className="card-body p-3 position-relative">
							<div className="row">
								<div className="col-7 text-start">
									<p className="text-sm mb-1 text-capitalize font-weight-bold">
										Avg. Revenue
									</p>
									<h5 className="font-weight-bolder mb-0">$1.200</h5>
									<span className="font-weight-normal text-secondary text-sm">
										<span className="font-weight-bolder text-success">
											+$213
										</span>{" "}
										since last month
									</span>
								</div>
								<div className="col-5">
									<div className="dropdown text-end">
										<a
											href="javascript:;"
											className="cursor-pointer text-secondary"
											id="dropdownUsers3"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<span className="text-xs text-secondary">
												6 May - 7 May
											</span>
										</a>
										<ul
											className="dropdown-menu dropdown-menu-end px-2 py-3"
											aria-labelledby="dropdownUsers3"
										>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 7 days
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last week
												</a>
											</li>
											<li>
												<a
													className="dropdown-item border-radius-md"
													href="javascript:;"
												>
													Last 30 days
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* <div className="row mt-4">
				<div className="col-12">
					<div className="card mb-4">
						<div className="card-header pb-0">
							<h6>Top Selling Products</h6>
						</div>
						<div className="card-body px-0 pt-0 pb-2">
							<div className="table-responsive p-0">
								<table className="table align-items-center mb-0">
									<thead>
										<tr>
											<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
												Product
											</th>
											<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
												Value
											</th>
											<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
												Ads Spent
											</th>
											<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
												Refunds
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div className="d-flex px-3 py-1">
													<div>
														<img
															src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg"
															className="avatar me-3"
															alt="image"
														/>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="mb-0 text-sm">Nike v22 Running</h6>
														<p className="text-sm font-weight-normal text-secondary mb-0">
															<span className="text-success">8.232</span> orders
														</p>
													</div>
												</div>
											</td>
											<td>
												<p className="text-sm font-weight-normal mb-0">
													$130.992
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm font-weight-normal mb-0">
													$9.500
												</p>
											</td>
											<td className="align-middle text-end">
												<div className="d-flex px-3 py-1 justify-content-center align-items-center">
													<p className="text-sm font-weight-normal mb-0">13</p>
													<i className="ni ni-bold-down text-sm ms-1 text-success" />
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex px-3 py-1">
													<div>
														<img
															src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-mug.jpg"
															className="avatar me-3"
															alt="image"
														/>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="mb-0 text-sm">
															Business Kit (Mug + Notebook)
														</h6>
														<p className="text-sm font-weight-normal text-secondary mb-0">
															<span className="text-success">12.821</span>{" "}
															orders
														</p>
													</div>
												</div>
											</td>
											<td>
												<p className="text-sm font-weight-normal mb-0">
													$80.250
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm font-weight-normal mb-0">
													$4.200
												</p>
											</td>
											<td className="align-middle text-end">
												<div className="d-flex px-3 py-1 justify-content-center align-items-center">
													<p className="text-sm font-weight-normal mb-0">40</p>
													<i className="ni ni-bold-down text-sm ms-1 text-success" />
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex px-3 py-1">
													<div>
														<img
															src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg"
															className="avatar me-3"
															alt="image"
														/>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="mb-0 text-sm">Black Chair</h6>
														<p className="text-sm font-weight-normal text-secondary mb-0">
															<span className="text-success">2.421</span> orders
														</p>
													</div>
												</div>
											</td>
											<td>
												<p className="text-sm font-weight-normal mb-0">
													$40.600
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm font-weight-normal mb-0">
													$9.430
												</p>
											</td>
											<td className="align-middle text-end">
												<div className="d-flex px-3 py-1 justify-content-center align-items-center">
													<p className="text-sm font-weight-normal mb-0">54</p>
													<i className="ni ni-bold-up text-sm ms-1 text-danger" />
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex px-3 py-1">
													<div>
														<img
															src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/bang-sound.jpg"
															className="avatar me-3"
															alt="image"
														/>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="mb-0 text-sm">Wireless Charger</h6>
														<p className="text-sm font-weight-normal text-secondary mb-0">
															<span className="text-success">5.921</span> orders
														</p>
													</div>
												</div>
											</td>
											<td>
												<p className="text-sm font-weight-normal mb-0">
													$91.300
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm font-weight-normal mb-0">
													$7.364
												</p>
											</td>
											<td className="align-middle text-end">
												<div className="d-flex px-3 py-1 justify-content-center align-items-center">
													<p className="text-sm font-weight-normal mb-0">5</p>
													<i className="ni ni-bold-down text-sm ms-1 text-success" />
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex px-3 py-1">
													<div>
														<img
															src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/photo-tools.jpg"
															className="avatar me-3"
															alt="image"
														/>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="mb-0 text-sm">
															Mountain Trip Kit (Camera + Backpack)
														</h6>
														<p className="text-sm font-weight-normal text-secondary mb-0">
															<span className="text-success">921</span> orders
														</p>
													</div>
												</div>
											</td>
											<td>
												<p className="text-sm font-weight-normal mb-0">
													$140.925
												</p>
											</td>
											<td className="align-middle text-center text-sm">
												<p className="text-sm font-weight-normal mb-0">
													$20.531
												</p>
											</td>
											<td className="align-middle text-end">
												<div className="d-flex px-3 py-1 justify-content-center align-items-center">
													<p className="text-sm font-weight-normal mb-0">121</p>
													<i className="ni ni-bold-up text-sm ms-1 text-danger" />
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* <footer className="footer py-4  ">
				<div className="container-fluid">
					<div className="row align-items-center justify-content-lg-between">
						<div className="col-lg-6 mb-lg-0 mb-4">
							<div className="copyright text-center text-sm text-muted text-lg-start">
								A dissertation by
								Augustine Nwabuwe
							</div>
						</div>
						
					</div>
				</div>
			</footer> */}
		</div>
	);
}

export default Dashboard