import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import {disableReactDevTools} from '@fvilers/disable-react-devtools';

//if(process.env.NODE_ENV === "production"){
    disableReactDevTools();
//}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </Provider>
  </React.StrictMode>
);