import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_ACCESS_TOKEN, LOGOUT } from "../../redux/slice/authSlice";
import axios from "../../api/axios";

const useRefreshToken = () => {
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const refresh = async () => {
		try{
			const response = await axios({
				method: "get",
				url: "/auth/token/refresh",
			});
			dispatch(SET_ACCESS_TOKEN(response.data.accessToken));//update with access token
			return response.data.accessToken;//return to original query to continue request
	    }
		catch(err){
			if(err?.response?.status == 401){
				dispatch(LOGOUT);
				navigate('/',{ state: { status: 'expired'} }); 
			}
		}
    }
    return refresh;
}

export default useRefreshToken