import React, {useEffect, useState} from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import timeLottie from "../../../assets/lottie/timemanagement.json";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {useDispatch} from "react-redux";
import {SET_ACTIVE_USER, LOGOUT} from "../../../redux/slice/authSlice";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import "../../../assets/admin/css/material-dashboard.css";


const Login = () => {
	 const [loginForm, setLoginForm] = useState({
		username: "",
		password: "",
	});
	const [forgotPassword,setForgotPassword] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const status = location?.state?.status;
	//const usernameRef = useRef(null);
	const {dispatchNotification} = useGlobalNotification();
	const {httpRequest}  = useAxios();
	
    useEffect(() => {
		//usernameRef.current.focus();
		if(status && status === "loggedOut"){
			dispatchNotification('success','You have logout successfully');
			dispatch(LOGOUT());
		}
		if(status && status === "expired"){
			dispatchNotification('success','You session has expired. Please re-login');
		}
		}, []);
	   const handleChange = (e) => {
			const name = e.target.name;
			const value = e.target.value;
			setLoginForm({ ...loginForm, [name]: value });
	   };

	const handleKeyPress = (e) =>{
		   if (e.key === "Enter")
			   handleSubmit(e);
	   }
    const handleSubmit = async (e) => {
		if(!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(loginForm.username)) {
			dispatchNotification('error', 'Email is not in appropriate format');
			return;
		}
	    if(loginForm.password.trim().length < 6 || loginForm.password.trim() === "") {
			dispatchNotification('error', 'Password must be a minimum of 6 characters');
			return;
		}

		await httpRequest("authenticate","POST",loginForm)
			.then((data) =>{
				if(data?.data?.auth){
					//clear state and redirect
					dispatch(SET_ACTIVE_USER(data.data.auth));
					navigate("/admin/dashboard");
					setLoginForm({ username: "", password: "" });
				}else{
					dispatchNotification('error','An error occurred');
				}
			});
	};

	return (
		<main className="main-content  mt-0">
			<section>
				<div className="page-header min-vh-100">
					<div className="container">
						<div className="row">
							<div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
								<Player
									autoplay
									loop
									style={{ height: "100vh", width: "100%" }}
									src={timeLottie}
								/>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
								<div className="card card-plain">
									<div className="card-header text-center">
										<img style={{width:"90px", height: "100px" , borderRadius : "15px"}} src={require("../../../uonsu_logo.jpg")} className="img-fluid animate__animated animate__pulse" alt="UoNSU Logo"/>
										<h4 className="font-weight-bolder">
											UONSU - DMS
										</h4>
										<p className="mb-0">{ forgotPassword ? "Enter Email to reset password"  :"Enter your email and password to sign in"}</p>
									</div>

										<div className="card-body mt-2">

											<div className="form-floating mb-3">
												<input name="username" type="email" id="username"
													   className={`form-control  ${ !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(loginForm.username) ? "is-invalid" : "is-valid"}`}
													   value={loginForm.username}
													   onChange={(e)=>handleChange(e)}
													   placeholder="Enter email address"/>
												<label htmlFor="username">Email address</label>
											</div>
											{forgotPassword ?
												<>
													<div className="text-center">
														<button
															onClick={()=>handleSubmit()}
															disabled={ !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(loginForm.username) ||  loginForm.username.trim() === "" || loginForm.password.trim().length < 6 || loginForm.password.trim() === ""}
															type="button"
															className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
														>
															RESET PASSWORD
														</button>
													</div>
												</> :
												<>
													<div className="form-floating">
														<input name="password" type="password" className={`form-control ${loginForm.password.trim() === "" || loginForm.password.trim().length < 6 ? "is-invalid" : "is-valid"} `} id="password"
															   value={loginForm.password}
															   onChange={(e)=>handleChange(e)}
															   onKeyDown={(e)=>handleKeyPress(e)}
															   placeholder="Password"/>
															<label htmlFor="password">Password</label>
													</div>


													<div className="text-center">
														<button
															onClick={()=>handleSubmit()}
															disabled={ !( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(loginForm.username) ||  loginForm.username.trim() === "" || loginForm.password.trim().length < 6 || loginForm.password.trim() === ""}
															type="button"
															className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
														>
															LOGIN
														</button>
													</div>
											  </>
											}

											<div className="text-center pt-3">
											<Link to="#" onClick={()=>setForgotPassword(!forgotPassword)}>

												<span>
													{
														forgotPassword ?
														<i className="material-icons-round" style={{verticalAlign: "middle"}}>keyboard_backspace</i>:
														<i className="material-icons-round" style={{verticalAlign: "middle"}}>lock_reset</i>
													}

													{ forgotPassword ? "Back to Login" : "Forgot Password" }

												</span>
											</Link>
											</div>
										</div>


									<div className="card-footer text-center pt-0 px-lg-2 px-1">
										<p className="mb-4 text-sm mx-auto">
											© 2022 , University of Nottingham Student Union Data Management System
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Login;
