import React, {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";

const DesignationManager = () => {
    const [allData, setAllData] = useState([]);
	const {httpRequest}  = useAxios();

	const handleActivateDeactivate = async (id,status) => {
		httpRequest(`designation/status/update/${status}/${id}`,'PATCH',{id,status}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.designationId === id ? {...data,designationActive: status} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const loadData = async () => {
		httpRequest('designation/all','GET').then((data) =>{
			if(data?.data){
				setAllData(data.data.designations);
			}
		});
	}

	useEffect(()=>{
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_DESIGNATION_CREATE">
							<Link to="new">
								<button type="button" className="btn btn-icon btn-info">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">CREATE DESIGNATION</span>
								</button>
							</Link>
						</AuthorizedAreas>

						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>Designation Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_MENU_DESIGNATION_MANAGE">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S/N</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Designation</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.designationId}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.designationName }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.designationActive === 1 ? "success" : "primary"}`}>
																	{s.designationActive === 1 ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
														
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">

																		{/*<AuthorizedAreas permission="CAN_DESIGNATION_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`view`}
																					state={{
																						id: s.designationId,
																						name: s.designationName
																					}}
																				>
																					VIEW DESIGNATION
																				</Link>
																			</li>
																		</AuthorizedAreas>*/}
																		
																		<AuthorizedAreas permission="CAN_DESIGNATION_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{ 
																						id: s.designationId,
																						name: s.designationName
																					}}
																				>
																					EDIT DESIGNATION
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_DESIGNATION_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.designationId,s.designationActive === 1 ? 2 : 1)}
																				>
																					{s.designationActive === 1 ? "DE-ACTIVATE" : "ACTIVATE"} DESIGNATION
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default DesignationManager