import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate } from "react-router-dom";
import { UserCreateValidation } from "../../../utils/validations/UserCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewUser = () => {
  const defaultForm = {
		firstname: "",
		lastname: "",
		email: "",
		designationId: "",
        roleId:""
  };
  const [form, setForm] = useState(defaultForm);
  const [designations,setDesignations] = useState([]);
  const [permissions,setPermissions] = useState([]);
  const [permissionCategories,setPermissionCategories] = useState([]);
  const [roles,setRoles] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {dispatchNotification} = useGlobalNotification();
  const {httpRequest}  = useAxios();

  const getInitializationData = async () =>{
	await httpRequest('user/initialize','GET')
			.then((data) =>{
				if(data){
					setDesignations(data.data.designations);
					setRoles(data.data.roles);
                    setPermissionCategories(data.data.permissionsCategory);
				}
	}); 
  }

  useEffect(() => {
		getInitializationData();
  },[]);

  const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm(prev=>({ ...prev, [name]: value })); 
   };

  const handleRoleChange = async (e) =>{
		if (e.target.value === "") return;

		handleChange(e);
		dispatchNotification('success','Fetching associated privileges...');
        await httpRequest(`role/permissions/${e.target.value}`,"GET")
            .then((data) =>{
                if(data){
                    setPermissions(data.data.permissions);
                }
        });
  }
  
  const handleSubmit = async (e) => {
        e.preventDefault();

        //validation
        UserCreateValidation.validate(form, { abortEarly: false })
        .then(async (values)=>{
            await httpRequest('user/save','POST',form)
            .then((data) =>{
                if(data){
                    dispatchNotification('success',"User account successfully created. Details sent to users");
                   	//setForm(defaultForm);
                    setTimeout(()=>{navigate(-1);},1000);
                }
            });   
        })
        .catch(err=>{
            let message = "<p>You have the following errors:</p>";
            message  += err.errors.join("<br/>");
            dispatchNotification('error',message);
        });
    };
	
	
	return <AuthorizedAreas permission="CAN_USER_CREATE">

	<div className="card-body px-0 pt-0 pb-2">
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
				<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>User Information</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="firstname"
                          id="firstname"
                          className="form-control"
                          placeholder="First Name"
                          value={form.firstname}
						  onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
					 <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="lastname"
                          id="lastname"
                          className="form-control"
                          placeholder="Last Name"
                          value={form.lastname}
						  onChange={(e)=>handleChange(e)}						
                        />
                      </div>
				   </div>
                </div>

				<div className="row mt-4">
					<div className="col-12">
                      <div className="input-group input-group-static">
                        <label>Email</label>
                        <input
                          autoComplete="off"
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          value={form.email}
						  onChange={e=>handleChange(e)}						
                        />
                      </div>
                    </div>
				</div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="input-group input-group-static">
                                <label htmlFor="designationId">Designation</label>
                                <select
                                    className="form-control"
                                    name="designationId"
                                    id="designationId"
                                    autoComplete="off"
                                    defaultValue={""}
                                    value={form.designationId}
                                    onChange={e=>handleChange(e)}
                                >
                                    <option value=""> --Select Designation--</option>
                                    { designations?.length && designations.map((e) => (
                                        <option value={e.designationId}>{e.designationName}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="roleId">Role</label>
                        <select
                          className="form-control"
                          name="roleId"
                          id="roleId"
                          autoComplete="off"
                          defaultValue={""}
						  value={form.roleId}
						  onChange={e=>handleRoleChange(e)}
                        >
                          <option value=""> --Select Role--</option>
                          { roles?.length && roles.map((e) => (
							<option value={e.roleId}>{e.roleName}</option>
							))
						  }
                        </select>
                      </div>
                    </div>
                  </div>
                  <h6 className="roleNamePermissionShow mt-3">Permissions
                    <span className="roleNamePermission text-primary" />
                  </h6>
                    {
                        permissionCategories.length > 0 ?   permissionCategories.map(
                                pc   =>
                                    <div className="row mt-4">
                                        <h6>{pc.permissionCategory}</h6>
                                        {
                                            permissions.length > 0 ? permissions.map((p) => (
                                                      p.permissionCategoryId === pc.permissionCategoryId ?

                                                                <div key={p.permissionId} className="col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="form-check form-switch">
                                                                        <input value={p.permissionId} checked={true} disabled={true}
                                                                               className="form-check-input"  type="checkbox" />
                                                                        <label  className="form-check-label">{p.permissionName}</label>
                                                                    </div>
                                                                </div>

                                                        :
                                                          null

                                                )) :
                                                null
                                        }
                                    </div>
                            )
                            :
                            null
                    }
                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
					<span className="btn-inner--icon"> <i className="material-icons">save</i></span>
					<span className="btn-inner--text">CREATE USER</span>
				</button>

                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default NewUser