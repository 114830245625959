import React, {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import {Link, useNavigate} from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";

const PermissionsCategoryManager = () => {

    const [allData, setAllData] = useState([]);
	const {httpRequest}  = useAxios();
	const navigate = useNavigate();

	const handleActivateDeactivate = async (id,status) => {
		httpRequest(`role/permissions/category/status/update/${status}/${id}`,'PATCH',{id,status}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.permissionCategoryId === id ? {...data,permissionCategoryActive: status} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const loadData = async () => {
		httpRequest('role/permissions/category/all','GET').then((data) =>{
			if(data?.data){
				setAllData(data.data.permissionsCategory);
			}
		});
	}

	useEffect(()=>{
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary m-2">
							<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
							<span className="btn-inner--text">GO BACK</span>
						</button>
						<AuthorizedAreas permission="CAN_ROLE_PERMISSIONS_CATEGORY_CREATE">
							<Link to="create">
								<button type="button" className="btn btn-icon btn-info m-2">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text"> CREATE PERMISSION CATEGORY</span>
								</button>
							</Link>
						</AuthorizedAreas>


						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>Permissions Category Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_ROLE_PERMISSIONS_CATEGORY_MANAGE">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S/N</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Category</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData?.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.permissionCategoryId}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.permissionCategory}
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.permissionCategoryActive === 1 ? "success" : "primary"}`}>
																	{s.permissionCategoryActive === 1 ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
														
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		
																		<AuthorizedAreas permission="CAN_ROLE_PERMISSIONS_CATEGORY_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{ 
																						id: s.permissionCategoryId,
																						name: s.permissionCategory
																					}}
																				>
																					EDIT PERMISSION CATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_ROLE_PERMISSIONS_CATEGORY_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.permissionCategoryId,s.permissionCategoryActive === 1 ? 2 : 1)}
																				>
																					{s.permissionCategoryActive === 1 ? "DE-ACTIVATE" : "ACTIVATE"} PERMISSION CATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default PermissionsCategoryManager