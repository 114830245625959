import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { UserCreateValidation } from "../../../utils/validations/UserCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const EditUser = () => {
        const defaultForm = {
                firstname: "",
                lastname: "",
                email: "",
                designationId: "",
                roleId: ""
        };
        const location = useLocation();
        const userId = location?.state?.userId;

        const [form, setForm] = useState(defaultForm);
        const [roles,setRoles] = useState([]);
        const [designations,setDesignations] = useState([]);
        const [permissions,setPermissions] = useState([]);
        const [permissionCategories,setPermissionCategories] = useState([]);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {dispatchNotification} = useGlobalNotification();
        const {httpRequest}  = useAxios();

        const getInitializationData = async () =>{
           // if(!userId) dispatchNotification("error", "");
            await httpRequest(`user/edit/${userId}`,'GET')
                    .then((data) =>{
                        if(data){
                            setRoles(data.data.roles);
                            setDesignations(data.data.designations);
                            setPermissions(data.data.permissions);
                            setForm({...data.data.user});
                            setPermissionCategories(data.data.permissionsCategory);
                        }
            }); 
        }

        useEffect(() => {
                getInitializationData();
        },[]);

        const handleChange = (e) => {
                const name = e.target.name;
                const value = e.target.value;
                setForm(prev=>({ ...prev, [name]: value })); 
        };

      const handleRoleChange = async (e) =>{
          if (e.target.value === "") return;

          handleChange(e);
          dispatchNotification('success','Fetching associated privileges...');
          await httpRequest(`role/permissions/${e.target.value}`,"GET")
              .then((data) =>{
                  if(data){
                      setPermissions(data.data.permissions);
                  }
              });
      }

    const handleSubmit = async (e) => {
          e.preventDefault();

          //validation
          UserCreateValidation.validate(form, { abortEarly: false })
              .then(async (values)=>{
                  await httpRequest(`user/edit/save/${userId}`,'PATCH',form)
                      .then((data) =>{
                          if(data){
                              dispatchNotification('success',"User edits saved");
                              //setForm(defaultForm);
                              //setPermissions([]);
                              setTimeout(()=>{navigate(-1);},1000);
                          }
                      });
              })
              .catch(err=>{
                  let message = "<p>You have the following errors:</p>";
                  message  += err.errors.join("<br/>");
                  dispatchNotification('error',message);
              });
      };

	return <AuthorizedAreas permission="CAN_USER_EDIT">
            <div className="card-body px-0 pt-0 pb-2">

                  <form onSubmit={(e)=>handleSubmit(e)}>
                    <div style={{ padding: 20 }}>
                        <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                                <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                                <span className="btn-inner--text">GO BACK</span>
                            </button>
                      <div className="card mt-4" id="basic-info">
                        <div className="card-header">
                          <h5>User Information</h5>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-6">
                              <div className="input-group input-group-static">
                                <label htmlFor="firstname">First Name</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  className="form-control"
                                  placeholder="First Name"
                                  disabled={true}
                                  value={form.firstname}
                                  onChange={(e)=>handleChange(e)}
                                />
                              </div>
                            </div>
                             <div className="col-6">
                              <div className="input-group input-group-static">
                                <label htmlFor="lastname">Last Name</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  className="form-control"
                                  placeholder="Last Name"
                                  disabled={true}
                                  value={form.lastname}
                                  onChange={(e)=>handleChange(e)}
                                />
                              </div>
                           </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                              <div className="input-group input-group-static">
                                <label>Email</label>
                                <input
                                  autoComplete="off"
                                  type="email"
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  value={form.email}
                                  disabled={true}
                                  onChange={e=>handleChange(e)}
                                />
                              </div>
                            </div>
                        </div>

                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="input-group input-group-static">
                                <label htmlFor="designationId">Designation</label>
                                <select
                                  className="form-control"
                                  name="designationId"
                                  id="designationId"
                                  autoComplete="off"
                                  defaultValue={""}
                                  value={form.designationId}
                                  onChange={e=>handleChange(e)}
                                >
                                  <option value=""> --Select Designation--</option>
                                  { designations?.length && designations.map((e) => (
                                    <option value={e.designationId}>{e.designationName}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="roleId">Role</label>
                                    <select
                                        className="form-control"
                                        name="roleId"
                                        id="roleId"
                                        autoComplete="off"
                                        defaultValue={""}
                                        value={form.roleId}
                                        onChange={e=>handleRoleChange(e)}
                                    >
                                        <option value=""> --Select Role--</option>
                                        {
                                            roles?.length && roles.map((e) => (
                                            <option value={e.roleId}>{e.roleName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <h6 className="roleNamePermissionShow mt-3">Permissions
                            <span className="roleNamePermission text-primary" />
                        </h6>
                            {
                                permissionCategories.length > 0 ?   permissionCategories.map(
                                        pc   =>
                                            <div className="row mt-4">
                                                <h6>{pc.permissionCategory}</h6>
                                                {
                                                    permissions.length > 0 ? permissions.map((p) => (
                                                            p.permissionCategoryId === pc.permissionCategoryId ?

                                                                <div key={p.permissionId} className="col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="form-check form-switch">
                                                                        <input value={p.permissionId} checked={true} disabled={true}
                                                                               className="form-check-input"  type="checkbox" />
                                                                        <label  className="form-check-label">{p.permissionName}</label>
                                                                    </div>
                                                                </div>

                                                                :
                                                                null

                                                        )) :
                                                        null
                                                }
                                            </div>
                                    )
                                    :
                                    null
                            }

                         <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                            <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                            <span className="btn-inner--text">SAVE EDITS</span>
                        </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
		</AuthorizedAreas>;
}

export default EditUser