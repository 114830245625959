import React from 'react';
import { useDispatch} from "react-redux";
import {SET_TRIGGER_ALERT_MESSAGE, SET_TRIGGER_ALERT_TYPE, SET_TRIGGER_ALERT} from "../../redux/slice/authSlice";

const useGlobalNotification = () => {
   const dispatch = useDispatch();
   const dispatchNotification = (the_type,the_message) =>{
        dispatch(SET_TRIGGER_ALERT_TYPE(the_type));
        dispatch(SET_TRIGGER_ALERT_MESSAGE(the_message));
        dispatch(SET_TRIGGER_ALERT(true));
    }
    return {dispatchNotification}
}

export default useGlobalNotification;